import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import GridLayout from "../../components/GridLayout"
import PageLayout from "../../components/PageLayout"
import GridTextProjectElement from "../../components/GridTextProjectElement"
import { projectNames } from "../../context/consts"
import Seo from "../../components/Seo"


const PrometeoPerfusionPage = () => {

    const imageComponents = [
        <StaticImage key={0} src="./../../images/prometeo/prometeo-0.jpg" alt="" layout="fullWidth"></StaticImage>,
        <GridTextProjectElement key={1}  projectName={projectNames.PROMETEO} />,
        <StaticImage key={2} src="./../../images/prometeo/prometeo-1.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={3} src="./../../images/prometeo/prometeo-2.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={4} src="./../../images/prometeo/prometeo-3.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={5} src="./../../images/prometeo/prometeo-4.jpg" alt="" layout="fullWidth"></StaticImage>,
    ]

    return (
        <PageLayout firstHead="prometeo" secondHead="perfusionmachine">
            <GridLayout components={imageComponents}></GridLayout>
            <Seo subTitle="prometeo perfusionmachine" />
        </PageLayout>
    )

}

export default PrometeoPerfusionPage